/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from "gatsby"
import CalendarIcon from '../images/calendar_icon.png'
import FormIcon from '../images/form_icon.png'
import JourneyIcon from '../images/journey_icon.png'
import TeamIcon from '../images/team_icon.png'
import Complete from '../components/complete_alert'

const Phase1Layout = ({ broker_slug, team_member_name, team_member_phone, team_member_email, form_completed  }) => {
    var path = broker_slug;
    return (
        <Container>
            <Row>
            <Col sm>
                <div className="buttonBox">
                <a href="https://form.jotform.com/91573772835165" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}>
                    <h4 style={{color:"#1e1749"}}>
                    <img src={FormIcon} alt="calendar icon" style={{width:"50px",marginBottom:"0px",marginRight:"12px"}} />
                        Fulfillment Form</h4>
                </a>
                {form_completed && (
                    <Complete />
                )}
                </div>
            </Col>
            <Col sm>
                <div className="buttonBox">
                <Link to={`${path}/team/`} style={{textDecoration:"none"}}>
                    <h4 style={{color:"#1e1749"}}>
                    <img src={TeamIcon} alt="calendar icon" style={{width:"50px",marginBottom:"0px",marginRight:"12px"}} />
                        Meet Your Team</h4>
                </Link>
                </div>
            </Col>
            </Row>
            <Row style={{paddingTop:"20px",paddingBottom:"20px"}}>
            <Col sm>
                <div className="buttonBox">
                <Link to={`${path}/calendar/`} style={{textDecoration:"none"}}>
                    
                    <h4 style={{color:"#1e1749"}}>
                    <img src={CalendarIcon} alt="calendar icon" style={{width:"50px",marginBottom:"0px",marginRight:"12px"}} />
                        Countdown Calendar</h4>
                </Link>
                </div>
            </Col>
            <Col sm>
                <div className="buttonBox">
                <a href="/assets/elevate_broker.pdf" style={{textDecoration:"none"}}>
                <h4 style={{color:"#1e1749"}}>
                <img src={JourneyIcon} alt="calendar icon" style={{width:"50px",marginBottom:"0px",marginRight:"12px"}} />
                    Onboarding Journey
                </h4>
                </a>
                </div>
            </Col>
            </Row>
            <Row>
            <Col>
                <div style={{textAlign:"center",marginTop:"30px"}}>
                <h3>Questions?</h3>
                <div>Contact your Implementation Manager:</div>
                <h4>{team_member_name}</h4>
                <div>{team_member_phone}</div>
                <div><a href={'mailto:'+team_member_email}>{team_member_email}</a></div>
                </div>
            </Col>
            </Row>
        </Container>
    )
}

Phase1Layout.propTypes = {
    broker_slug: PropTypes.node.isRequired,
}

export default Phase1Layout
