import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageContent from "../components/page_content"
import Phase1Layout from '../components/phase1_layout'
import Phase2Layout from '../components/phase2_layout'
import "../components/broker-styles.css"
import AppIcon from '../images/app_icon.png'
import Helmet from "react-helmet"

const Broker = props => {
    //var parentPage = props.pageContext.broker_slug;
    console.log(props.data.strapiBroker);
    var path = props.data.strapiBroker.slug;
    var team_member = '';
    var layout = {};

    if(props.data.strapiBroker.Phase === 'Phase 1'){
      team_member = props.data.strapiBroker.teammembers.find(function(element) {
        if(element.role === 'Implementation Manager'){
          return element;
        }
        return {name:'',phone:'',email:''};
        
      });

      layout = <Phase1Layout broker_slug={path} team_member_name={team_member.name} team_member_phone={team_member.phone} team_member_email={team_member.email} form_completed={props.data.strapiBroker.fulfillment_form_completed} />
    } 
    if(props.data.strapiBroker.Phase === 'Phase 2'){
      team_member = props.data.strapiBroker.teammembers.find(function(element) {
        if(element.role === 'Account Manager'){
          return element;
        }
        return {name:'',phone:'',email:''};
      });

      layout = <Phase2Layout broker_slug={path} team_member_name={team_member.name} team_member_phone={team_member.phone} team_member_email={team_member.email} />
    }   

    return (
  <Layout logo={props.pageContext.broker_logo}>
    <Helmet>
      <link rel="apple-touch-icon" href={AppIcon} />
      <link rel="icon" sizes="192x192" href={AppIcon} />
      <meta name="apple-mobile-web-app-title" content="Elevate BOSS"></meta>
    </Helmet>
    <SEO title="Broker" />
    <PageContent>
      {layout}
    </PageContent>
  </Layout>
)
}

export default Broker

export const query = graphql`
  query ($broker_slug: String) {
    strapiBroker(slug: {eq: $broker_slug}) {
      id
      Phase
      slug
      name
      fulfillment_form_completed
      teammembers {
        email
        name
        phone
        role
      }
      logo {
        publicURL
      }
    }
  }
`;