/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Link } from "gatsby"
import DemoIcon from '../images/demo_icon.png'
import SubscribeIcon from '../images/subcribe_icon.png'
import HelpIcon from '../images/helpcenter_icon.png'
import WebinarIcon from '../images/webinar_icon.png'


const Phase2Layout = ({ broker_slug, team_member_name, team_member_phone, team_member_email  }) => {
    var path = broker_slug;
    return (
        <Container>
            {/*
            <Row>
            <Col sm>
                <div className="buttonBox">
                <Link to={`${path}/demo`} style={{textDecoration:"none"}}>
                
                    <h4 style={{color:"#1e1749"}}><img src={DemoIcon} alt="demo icon" style={{width:"50px",marginBottom:"0px",marginRight:"12px"}} />Elevate Demo</h4>
                </Link>
                </div>
            </Col>
            <Col sm>
                <div className="buttonBox">
                <Link to={`${path}/signup`} style={{textDecoration:"none"}}>
                    <h4 style={{color:"#1e1749"}}><img src={SubscribeIcon} alt="subscribe icon" style={{width:"50px",marginBottom:"0px",marginRight:"12px"}} />Subscribe Today</h4>
                </Link>
                </div>
            </Col>
            </Row>
            */}
            <Row style={{paddingTop:"20px",paddingBottom:"20px"}}>
            <Col sm>
                <div className="buttonBox">
                <Link to={`${path}/webinars`} style={{textDecoration:"none"}}>
                    <h4 style={{color:"#1e1749"}}>
                    <img src={WebinarIcon} alt="webinar icon" style={{width:"50px",marginBottom:"0px",marginRight:"12px"}} />
                        On-Demand Webinars/Video</h4>
                </Link>
                </div>
            </Col>
            <Col sm>
                <div className="buttonBox">
                <a href="https://elmstreettechnology.zendesk.com" target="_blank" rel="noopener noreferrer" style={{textDecoration:"none"}}>
                <h4 style={{color:"#1e1749"}}>
                <img src={HelpIcon} alt="help icon" style={{width:"50px",marginBottom:"0px",marginRight:"12px"}} />
                    Elevate Help Center
                </h4>
                </a>
                </div>
            </Col>
            </Row>
            <Row>
            <Col>
                <div style={{textAlign:"center"}}>
                <h3>Questions?</h3>
                <div>Contact your Account Manager:</div>
                <h4>{team_member_name}</h4>
                <div>{team_member_phone}</div>
                <div><a href={'mailto:'+team_member_email}>{team_member_email}</a></div>
                </div>
            </Col>
            </Row>
        </Container>
    )
}

Phase2Layout.propTypes = {
    broker_slug: PropTypes.node.isRequired,
}

export default Phase2Layout
